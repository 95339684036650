import { createGlobalStyle } from "styled-components";

import MontserratBold from "../../fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../fonts/Montserrat-Medium.ttf";
import MontserratRegular from "../../fonts/Montserrat-Regular.ttf";
import MontserratLight from "../../fonts/Montserrat-Light.ttf";
import MontserratSemiBold from "../../fonts/Montserrat-SemiBold.ttf";

import RobotoRegular from "../../fonts/Roboto-Regular.ttf";
import RobotoMedium from "../../fonts/Roboto-Medium.ttf";
import RobotoBold from "../../fonts/Roboto-Bold.ttf";

import NunitoRegular from '../../fonts/Nunito-Regular.ttf';
import NunitoMedium from '../../fonts/Nunito-Medium.ttf';
import NunitoLight from '../../fonts/Nunito-Light.ttf';
import NunitoBold from '../../fonts/Nunito-Bold.ttf';

export const GlobalFonts = createGlobalStyle`

  @font-face {
    font-display: fallback;
    font-family:"Montserrat-Light";
    src: url(${MontserratLight}) format("truetype");
    font-display: block;
    font-weight: 300;
  };

  @font-face {
    font-display: fallback;
    font-family:"Montserrat";
    src: url(${MontserratRegular}) format("truetype");
    font-display: block;
    font-weight: 400;
  };

  @font-face {
    font-display: fallback;
    font-family:"Montserrat-Medium";
    src: url(${MontserratMedium}) format("truetype");
    font-display: block;
    font-weight: 500;
  };

  @font-face {
    font-display: fallback;
    font-family:"Montserrat-Bold";
    src: url(${MontserratBold}) format("truetype");
    font-display: block;
    font-weight: bold;
  };

  @font-face {
    font-display: fallback;
    font-family:"Montserrat-SemiBold";
    src: url(${MontserratSemiBold}) format("truetype");
    font-display: block;
    font-weight: 500;
  };  

  @font-face {
  font-family: "Roboto";
  src: url(${RobotoRegular}) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(${RobotoMedium}) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(${RobotoBold}) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url(${NunitoRegular}) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito-Medium";
  src: url(${NunitoMedium}) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito-Light";
  src: url(${NunitoLight}) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
  
@font-face {
  font-family: "Nunito-Bold";
  src: url(${NunitoBold}) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
`;

export const FONT_FAMILY = {
  MONTSERRAT: "font-family: Montserrat;",
  ROBOTO: "font-family: Roboto;",
};
